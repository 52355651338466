
    /**************

    Multi Digital
    Bahnhofstrasse 14
    9470 Buchs
    Tel. 081 750 02 02
    info@multidigital.ch
    www.multidigital.ch

    ***************/

    // Multi Digital Settings
    $enable-rounded: false !default;