@font-face {
  font-family: 'Volte-RegularItalic';
  src: url('../fonts/Volte-RegularItalic.eot');
  src: url('../fonts/Volte-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Volte-RegularItalic.woff2') format('woff2'),
  url('../fonts/Volte-RegularItalic.woff') format('woff'),
  url('../fonts/Volte-RegularItalic.ttf') format('truetype'),
  url('../fonts/Volte-RegularItalic.svg#Volte-RegularItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volte-Bold';
  src: url('../fonts/Volte-Bold.eot');
  src: url('../fonts/Volte-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Volte-Bold.woff2') format('woff2'),
  url('../fonts/Volte-Bold.woff') format('woff'),
  url('../fonts/Volte-Bold.ttf') format('truetype'),
  url('../fonts/Volte-Bold.svg#Volte-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volte-Semibold';
  src: url('../fonts/Volte-Semibold.eot');
  src: url('../fonts/Volte-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Volte-Semibold.woff2') format('woff2'),
  url('../fonts/Volte-Semibold.woff') format('woff'),
  url('../fonts/Volte-Semibold.ttf') format('truetype'),
  url('../fonts/Volte-Semibold.svg#Volte-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Volte-Regular';
  src: url('../fonts/Volte-Regular.eot');
  src: url('../fonts/Volte-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Volte-Regular.woff2') format('woff2'),
  url('../fonts/Volte-Regular.woff') format('woff'),
  url('../fonts/Volte-Regular.ttf') format('truetype'),
  url('../fonts/Volte-Regular.svg#Volte-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VolteRounded-Regular';
  src: url('../fonts/VolteRounded-Regular.eot');
  src: url('../fonts/VolteRounded-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VolteRounded-Regular.woff2') format('woff2'),
  url('../fonts/VolteRounded-Regular.woff') format('woff'),
  url('../fonts/VolteRounded-Regular.ttf') format('truetype'),
  url('../fonts/VolteRounded-Regular.svg#VolteRounded-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'VolteRounded-Semibold';
  src: url('../fonts/VolteRounded-Semibold.eot');
  src: url('../fonts/VolteRounded-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/VolteRounded-Semibold.woff2') format('woff2'),
  url('../fonts/VolteRounded-Semibold.woff') format('woff'),
  url('../fonts/VolteRounded-Semibold.ttf') format('truetype'),
  url('../fonts/VolteRounded-Semibold.svg#VolteRounded-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
