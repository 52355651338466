/*** Bootstrap ***/
@import "~bootstrap/scss/bootstrap";
@import "bootstrap_custom";

/*** Font Awesome Pro ***/
@import 'fontawesome/fontawesome';
@import 'fontawesome/light';
@import 'fontawesome/brands';
@import 'fontawesome/solid'; 

/*** OWL (Caroussel) ***/
@import '~owl.carousel/dist/assets/owl.carousel.css';

/*** AOS.js ***/
@import '~aos/dist/aos.css';


/*** lightGallery ***/
@import 'lightgallery/lightgallery';   


/**************

 Multi Digital
 Wiedenstrasse 52c
 CH-9470 Buchs

 T +41 58 344 92 41
 hello@multidigital.ch
 www.multidigital.ch

***************/

/* Schriften */
@import 'fonts';
$fontregular: 'Volte-Regular';
$fontsemibold: 'Volte-Semibold';
$fontbold: 'Volte-Bold';
$fontitalic: 'Volte-RegularItalic';
$fonttitle: 'VolteRounded-Semibold';

/* Farben */
$color1: #d31f4f; /* Hauptfarbe */
$colorwhite: #FFF; /* weiss */
$colorblack: #000; /* schwarz */
$colorlightgray: #efefef; /* hellgrau */
$colorlightgray: #F4F4F4; /* hellgrau */
$colorgray: #777777; /* grau */

/*** Standard ***/
body, html { width: 100%; -webkit-font-smoothing: antialiased !important;-moz-osx-font-smoothing: grayscale;text-rendering: geometricPrecision; scroll-behavior: smooth;}
body, p { font-family: $fontregular, Helvetica, Arial, sans-serif;  padding: 0; font-weight: normal; color: $colorblack; }
p { font-size: 18px;line-height:25px; font-family: $fontregular;margin-block-start: 0;margin-block-end: 0;
  a {color:$color1;
    &:hover {color:$colorblack;}  
  }
}
strong { font-family: $fontsemibold; font-weight: normal; }
ul, menu, dir { display: block; list-style-type: disc; -webkit-margin-before: 0em; -webkit-margin-after: 0em; -webkit-margin-start: 0px; -webkit-margin-end: 0px; -webkit-padding-start: 0px; padding-left: 0; }
hr { margin-top: 10px; margin-bottom: 10px; border: 0; border-top: 1px solid $colorlightgray;}
em {font-family: $fontregular; font-weight: normal; font-style: normal; }

/*** Formular Standard ***/
select { border-radius: 0; -webkit-appearance: none; -webkit-border-radius: 0px; background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%); background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em; background-size: 5px 5px, 5px 5px, 1px 1.5em; background-repeat: no-repeat; -moz-appearance: none; display: block; padding: 0.3rem; height: 2rem; width: 100%; }

/*** Backgrounds ***/
.background_lightgray { background: $colorlightgray;}

/*** Separator ***/
.separator-horizontal {position: relative;overflow: hidden;width: 100%;height: 40px;
                      &::before {position: absolute;left: 0%;width: 100%;height: 30px;border-radius: 100%;box-shadow: 0 0 40px hsla(0,0%,83.1%,0.5);content: "";transform: translateY(-100%);}
}

/*** Buttons ***/
.btn.btn-primary { background: $color1;border: 0 none; padding: 10px 25px 10px 25px; border-radius: 26px; text-transform: uppercase; font-size: 14px; line-height: 21px; font-family: $fontsemibold; position: relative;
                 &:hover {background: $colorblack;}
                 &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {background-color:$colorblack;border-color:transparent;}
                 &.focus,&:focus {box-shadow:none;}
                 &.white {background:$colorwhite; color:$colorblack;
                         &:hover { background: $colorblack; color:$colorwhite;} 
                 } 
}
.btn.btn-secondary { background: none;border: 0 none; padding: 0; text-transform: none; font-size: 24px; line-height: 31px; font-family: $fontregular; color:$color1; 
                    &:hover {background: none;color:$colorblack;}
                    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {background: none;color:$colorblack;}
                    &.focus,&:focus {box-shadow:none;}
}
.btn.btn-light {color:$color1;background-color:transparent;border-color:transparent; border: 0 none;padding:0; text-transform: uppercase; font-size: 12px;line-height: 19px; letter-spacing: 1px; font-family: $fontregular;
                &:hover {background: transparent; color:$color1;}
                &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {background-color:$color1;border-color:transparent;}
                &.focus,&:focus {box-shadow:none;}
}
.btn.btn-icon { font-size: 12px; line-height: 19px; text-transform: uppercase; font-family: $fontregular;color:$colorblack;
               img { height: 22px; margin-right: 4px;}
               &:hover {color:$color1;transition:0.25s;}  
} 
.btn.btn-ext-link { font-size: 20px; line-height: 27px;color:$colorblack; text-align: left; padding:0; margin-bottom: 25px;
                  i { color:$color1; margin-right: 5px;}
                   &:hover {color:$colorblack;transition:0.25s;}
}
.btn.btn-round { font-size: 22px; line-height: 29px; color:$colorblack; background: transparent; text-transform: uppercase; margin-right: 20px;
              .icon {width: 50px; height: 50px; border-radius: 25px; background: $color1; color:$colorwhite; display: inline-block; line-height: 50px;}
              &:hover {color:$color1;transition:0.25s;}
}
.btn.btn-category { font-size: 14px; line-height: 21px; font-family: $fontregular;color:$colorblack; background: $colorlightgray; border-radius: 0; text-align: left; padding: 15px;
                  img { height: 60px;  max-width: 60px;}
                  .icon {display: inline-block; width: 70px; text-align: center;}
                  &:hover {color:$colorwhite;transition:0.25s; background: $color1;}
}
.btn.btn-white {font-size: 12px; line-height: 19px; font-family: $fontsemibold; color:$color1; text-transform: uppercase; background:$colorwhite;border-radius: 0; padding:10px 20px;}
.btn.btn-pdf {font-size: 16px; line-height: 23px; color:$colorblack; background:transparent;border-radius: 0; padding:20px 0;
             i {color:$color1;}
             &:hover {color:$color1;transition:0.25s;}
}
.btn.btn-more { background: transparent; color:$color1; font-family: $fontregular; text-transform: uppercase; letter-spacing: 0.5px; padding: 10px 0; text-align: left;
              .icon {display: inline-block; float: right; font-size: 20px; line-height: 27px;}
}


/*** Links ***/
a { color: $colorblack; text-decoration: none;
  &:hover, &:focus { color: $color1; text-decoration: none; }
}

/*** Titelbild ***/
.titelbild { margin-top: 70px;}
.titelbild_platzhalter { height: 70px;}

/*** Loader ***/
.loader {display: flex;justify-content: center;align-items: center;flex-direction: column;position: fixed; top:0; left:0; right:0; bottom:0;background: $color1; z-index: 200; opacity: 0.95;
         .loader-circle { width: 10rem; height: 10rem;margin-top:-300px; border-radius: 50%; animation: spin 2s infinite;}
         .logo_preloader { width: 250px; position: absolute;bottom: 100px; }
         .text {text-align: center;text-transform: uppercase;color:$colorwhite;letter-spacing: 0.8rem;font-size: 1.2rem; margin-top: 30px;}
}


/*** Cookie Meldung ***/
#cookie { position: fixed; bottom:50px; left:50px; padding:25px; background: $color1; width: 600px; z-index: 20; display: none;
        p{color:$colorwhite; font-size: 18px; line-height:25px;
          a { color:$colorwhite; text-decoration: underline;
             &:hover { color:$colorblack;}  
          }
        }
        .btn { background: $colorwhite; border-radius: 0;
             &:hover { background: $colorblack; color:$colorwhite;}
        }
}

/*** Grid Layout ***/
.umb-grid {
          ul { list-style: square; margin-left: 20px;
             li { margin: 5px 0; font-size: 18px; line-height: 25px;
                &::marker { color: $color1;font-size: 30px;}
             } 
          }
          .fotogalerie.grid_modul { margin-left: -190px; margin-right: -190px; margin-top:50px; margin-bottom: 50px;
                                  .item {
                                        &:hover {opacity: 0.9; cursor: zoom-in;}
                                  }
          }
          .grid_modul.fotocollage {
                                  .item {
                                    &:hover {opacity: 0.9; cursor: zoom-in;}
                                  }
          }
          .grid_modul.foto {
                          .item {
                            &:hover {opacity: 0.9; cursor: zoom-in;}
                          }
          }
}

/*** Standard Text ***/
.standard_text {
               .lead {font-size: 22px; line-height: 29px;}
}
.kategorie { font-family: $fontsemibold; text-transform: uppercase; letter-spacing: 1.2px;color:$colorgray;}

/*** Header ***/
header { padding: 20px 60px; position: absolute; top:0; left:0; z-index: 10; width: 100%; ;
       .logo { float: left;
              img {width:70px; }
       }
       .menu {position: absolute;right:60px; margin-top: 20px;
             .menu_label {color: $colorwhite; font-size: 18px;margin-top:4px;position: absolute;right: 30px;
                         &.white {color:$colorwhite !important;}
             }
             .menu_icon {color: $color1;font-size: 27px;}
             &:hover { cursor: pointer;display: block;
                      .menu_label, .menu_icon  {color:$colorblack;}
             }
       }
}
.folgeseite {
            .menu {
                  .menu_label { color:$colorblack;}
            }
            header { position: relative;}
}

/*** Navigation ***/
.hauptnavigation { position: fixed;width: 100%;height: 100%;top: 0;overflow-y: scroll; background: rgba(211,31,79,0.97); color: $colorwhite; z-index: 999;
                  .navigation_header { position: absolute; background: $colorwhite;  padding: 20px 60px; left:0; right:0;
                                      .navigation_logo {float: left;
                                                        img {width:70px; }
                                      }
                                      .button_close { font-size: 40px; color:$color1; margin-top: 11px;
                                                    &:hover { color: $colorblack; cursor: pointer;}
                                      }
                  }
                  .navigation_box { margin-top: 230px;}
                  .navigation_text { margin-top: 20px;
                                   h3 { font-size:30px; line-height: 37px;}
                                   h3, p {color:$colorwhite;}
                                   .btn { background: $colorwhite; }
                  }
                  #navigation {font-size: 35px; line-height: 55px; text-transform: uppercase; color:$colorwhite;list-style: none; letter-spacing: 1px;
                              .navbar-nav {flex-direction: column;}
                              ul { list-style: none;
                                  li { display: block;
                                      a { color: $colorwhite; font-size: 40px;}
                                      &.active a { font-family: $fontsemibold;}
                                      &:hover a {color:$colorblack;}
                                  
                                  }
                              }
                  }
                  
}



/*** Hauptnavigation ***/
.cbp-spmenu-right {right: -5000px;
                  &.menu-open {right: 0px;}
}
.cbp-spmenu {-webkit-transition: all 0.4s ease;-moz-transition: all 0.4s ease;transition: all 0.4s ease; }






/*** Home Slider ***/
.home_slider { position: relative;height: 100vh;
             .slider_text {position: absolute; left:calc(50% - 550px); top:15%; z-index: 5; width: 1100px; text-align: center;
                          .text p { font-size: 80px; line-height: 90px; color:$colorwhite;font-family: $fonttitle; }
                          .btn { color:$colorwhite; border:2px solid $colorwhite; border-radius: 20px; font-size: 14px; font-family: $fontsemibold; padding:9px 30px; margin-top: 30px;
                               &:hover {color:$color1; border-color: $color1;} 
                          }
             }
            /* .verlauf {position: absolute; left:0; width: 100%; top:0; bottom:0; ;background: rgba(0,0,0,0.3); z-index: 2;} */
             video {position: absolute;right: 0;bottom: 0;min-width: 100%;min-height: 100%;height: 100vh;object-fit: cover;-o-object-position: center center;object-position: center center;pointer-events: none;}
            .next {position: absolute; left:50%;color: $colorwhite; font-size: 30px; margin-left: -15px; text-align: center; bottom:50px; z-index: 100;display: inline-block;}
}
#home_carousel {
                .carousel-item { position:relative;height: 100vh;min-height: 350px;background: no-repeat center center scroll;-webkit-background-size: cover;-moz-background-size: cover;-o-background-size: cover;background-size: cover;}
}  

/*** Willkommen ***/
.willkommen_box {  padding: 80px 0 90px 0;
                h1 { margin-bottom: 20px; color:$color1;}
                p { margin-bottom: 30px; color:$colorblack;}
                .btn.round { background: $color1; color:$colorwhite; text-transform: uppercase; font-size:14px; line-height: 21px; font-family: $fontsemibold; border-radius: 20px; padding:10px 30px;
                           &:hover {background: $colorblack;} 
                }
}

/*** Leistungen ***/


.leistungen_home { padding: 60px 0;  background:$colorlightgray;
                  .owl-stage {display: flex;flex-wrap: wrap;}
                  .owl-item {opacity: 0.4; display: flex;
                    &.active { opacity: 1 !important;}
                
                  }
                  .owl-nav {display: block;
                    .owl-prev {position: absolute;left: -80px;top: 230px;border:0 none;text-align: center;z-index: 5;width: 60px;height: 60px;border-radius: 30px !important;outline: 0;background:transparent !important;transition: all 0.3s;color:$color1 !important;;
                      i {font-size: 40px;}
                      &:hover { color:$colorblack !important;}
                    }
                    .owl-next {position: absolute;right: -80px;top: 230px;border: 5px solid #fff;text-align: center;z-index: 5;width: 60px;height: 60px;border-radius: 30px !important;outline: 0;background: transparent !important ;color:$color1 !important;transition: all 0.3s;;
                      i {font-size: 40px;}
                      &:hover { color:$colorblack !important;}
                    }
                  }
                  .item {position: relative;overflow: hidden; min-height: 0;display: flex;flex-direction: column; width: 100%; background: $colorwhite; margin-bottom: 30px;-webkit-transition: .1s ease-in-out;transition: .1s ease-in-out;
                        .text { padding:30px;
                              h4 { font-size: 22px; line-height: 29px; text-transform: uppercase; color:$colorblack; margin-bottom: 5px; font-family: $fontsemibold;}
                              p { font-size: 18px; line-height: 25px;}
                        }
                        .image {background: $color1;
                          img {opacity: 1;-webkit-transition: .1s ease-in-out;transition: .1s ease-in-out;}
                        }
                        &:hover { background: $color1;
                          h4, p {color:$colorwhite;}
                          .image img { opacity: 0;}
                        }
                  }
}

.leistungen_liste { 
                  .item {position: relative;overflow: hidden; min-height: 0;display: flex;flex-direction: column; width: 100%; background: $colorlightgray; margin-bottom: 30px;-webkit-transition: .1s ease-in-out;transition: .1s ease-in-out; height: calc(100% - 30px);
                        .text { padding:30px;
                              h4 { font-size: 22px; line-height: 29px; text-transform: uppercase; color:$colorblack; margin-bottom: 5px; font-family: $fontsemibold;}
                              p { font-size: 18px; line-height: 25px;}
                        }
                        .image {background: $color1;
                               img {opacity: 1;-webkit-transition: .1s ease-in-out;transition: .1s ease-in-out;} 
                        }
                        &:hover { background: $color1; 
                                h4, p {color:$colorwhite;}
                                .image img { opacity: 0;}
                        }
                  }
}


/*** Referenzen - Home ***/
#referenzen { margin: 100px 0;
  .kategorie {color:$colorgray; text-transform: uppercase; }
  .rahmen { height: 170px; margin-bottom: 30px; line-height: 170px; text-align: center;background: $colorwhite;
    img {max-width: 135px; max-height: 125px;-webkit-transition: all .25s cubic-bezier(0.43, 0.99, 0.67, 0.98);transition: all .25s cubic-bezier(0.43, 0.99, 0.67, 0.98); filter: grayscale(100%);opacity: 0.8; -webkit-transform: translateY(5px);transform: translateY(5px);}
    &:hover {
      a {display: block;}
      img {filter: grayscale(0%);-webkit-transform: translateY(0);transform: translateY(0);opacity: 1;-webkit-transition: opacity 2s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);transition: opacity 2s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);transition: opacity 2s cubic-bezier(0.19, 1, 0.22, 1),transform 2s cubic-bezier(0.19, 1, 0.22, 1);transition: opacity 2s cubic-bezier(0.19, 1, 0.22, 1),transform 2s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);}
    }
  }
}
.referenzen_liste {
                  .rahmen { height: 170px; margin-bottom: 60px; line-height: 170px; text-align: center;background: $colorwhite;
                          img {max-width: 135px;max-height: 125px;-webkit-transition: all .25s cubic-bezier(0.43, 0.99, 0.67, 0.98);transition: all .25s cubic-bezier(0.43, 0.99, 0.67, 0.98); filter: grayscale(100%);opacity: 0.8;  -webkit-transform: translateY(5px);transform: translateY(5px);}
                          &:hover {
                                   a {display: block;}
                                   img {filter: grayscale(0%);-webkit-transform: translateY(0);transform: translateY(0);opacity: 1;-webkit-transition: opacity 2s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);transition: opacity 2s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);transition: opacity 2s cubic-bezier(0.19, 1, 0.22, 1),transform 2s cubic-bezier(0.19, 1, 0.22, 1);transition: opacity 2s cubic-bezier(0.19, 1, 0.22, 1),transform 2s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 2s cubic-bezier(0.19, 1, 0.22, 1);}
                          }
                  }
}

/*** Footer ***/
footer { background: $color1; color:$colorwhite; padding:50px 0; margin-top: 60px;
        p {color:$colorwhite;}
        .logo_footer { margin-left:-10px; margin-bottom: 10px;
                     img { width: 120px;} 
        }
        a {border-bottom: 1px dotted $colorwhite; padding-bottom:1px; color:$colorwhite;
          &:hover {color:$colorblack;border-bottom: 1px dotted $colorblack;}
        }
}
.copyright { font-size: 18px; line-height: 25px; margin: 10px 0 30px 0;
            a {border-bottom: 1px dotted $colorblack; padding-bottom:1px;
              &:hover {color:$color1;border-bottom: 1px dotted $color1;}  
            }
}


/*** Über uns ***/
.team {
          article.item { position: relative;font-size: 18px; line-height: 25px; margin-bottom: 15px;
                                .vorname_name { font-family: $fontsemibold; margin-top: 5px;}
                                .hover {display: none; background: rgba(211,31,79,0.85); color:$colorwhite;font-size: 22px; line-height: 29px; position: absolute; top:0; left:0;right:0;bottom:0;
                                       .text_up { position: absolute; top:30px; left:30px; right:30px;
                                                .name {font-family: $fontsemibold;}
                                       }
                                       .text_down { position: absolute; bottom:30px;left:30px; right:30px; line-height: 30px;
                                                  a {color: $colorwhite; border-bottom: 1px dotted $colorwhite;
                                                    &:hover { color:$colorblack;}
                                                  }
                                       } 
                                }
                                &:hover .hover {display: block;}
                                &:hover .vorname_name {display: none;}
          }
}

/*** Überschriften ***/
h1 { font-size: 45px; line-height: 52px; font-family: $fonttitle; color:$color1; }
h2 {font-size:45px; line-height: 52px; font-family: $fonttitle; color:$color1; padding:0; margin:0; margin-bottom: 10px; }
h3 {font-size:40px;line-height: 47px;padding:0;margin:0; margin-bottom: 15px; font-family: $fonttitle;color:$colorblack;}
h4 {font-size:30px;line-height: 37px;font-weight:300; padding:0;margin:0;font-family: $fonttitle; }
h5 {font-size:25px;line-height: 32px;font-weight:300; padding:0;margin:0;font-family: $fonttitle; }

.font_18 {font-size: 18px; line-height: 25px;}
.font_20 {font-size: 20px; line-height: 27px;}
.font_25 {font-size: 25px; line-height: 32px;}
.font_30 {font-size: 30px; line-height: 37px;}
.font_35 {font-size: 35px; line-height: 42px;}


/*** Bounce Effekt ***/
.bounce { -moz-animation: bounce 3s infinite; -webkit-animation: bounce 3s infinite;animation: bounce 3s infinite;}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(5px);}
  40% {transform: translateY(20px);}
  60% {transform: translateY(10px);}
}

@import 'responsive';


