// Extra Extra large devices (ab 1599px )
@media (min-width: 1599px) {


}


// Extra large devices (Grosse Monitor, 1200px und grösser)
@media (min-width: 1200px) and (max-width: 1598px) {

}


// Large devices (Desktops, 992px und grösser)
@media (min-width: 992px) and (max-width: 1199.98px) {




}


// Medium devices (Tablets, 768px und grösser)
@media (min-width: 768px) and (max-width: 991.98px) {


}


// Small devices (Mobile - quer, 576px und grösser)
@media (min-width: 576px) and (max-width: 767.98px) {


}


// Extra small devices (Mobile - hoch, bis 576px)
@media (max-width: 575.98px) {

  /*** Home Slider ***/
  .home_slider { 
    .slider_text {position: absolute; left:0; top:25%;  width: 100%; 
      .text p { font-size: 50px; line-height: 57px;}
      .btn { margin-top: 20px;}
    }
    video {position: absolute;right: 0;bottom: 0;min-width: 100%;min-height: 100%;height: 100vh;object-fit: cover;-o-object-position: center center;object-position: center center;pointer-events: none;}
    .next {left:50%;color: $colorwhite; font-size: 30px; margin-left: -15px;}
  }

  .leistungen_home { padding: 60px 0;
                    .owl-nav {display: block; 
                             .owl-prev {left: 10px;top: 80px; background: $colorwhite !important;width: 40px;height: 40px; border-radius: 20px !important;
                                        i {font-size: 25px;}
                             }
                             .owl-next {right: 10px;top: 80px;background: $colorwhite !important;width: 40px;height: 40px; border-radius: 20px !important;
                                        i {font-size: 25px;}
                             }
                    }
    .item {position: relative;overflow: hidden; min-height: 0;display: flex;flex-direction: column; width: 100%; background: $colorwhite; margin-bottom: 30px;-webkit-transition: .1s ease-in-out;transition: .1s ease-in-out;
      .text { padding:30px;
        h4 { font-size: 22px; line-height: 29px; text-transform: uppercase; color:$colorblack; margin-bottom: 5px; font-family: $fontsemibold;}
        p { font-size: 18px; line-height: 25px;}
      }
      .image {background: $color1;
        img {opacity: 1;-webkit-transition: .1s ease-in-out;transition: .1s ease-in-out;}
      }
      &:hover { background: $color1;
        h4, p {color:$colorwhite;}
        .image img { opacity: 0;}
      }
    }
  }

  /*** Referenzen - Home ***/
  #referenzen { margin: 50px 0;
    .rahmen { height: 120px; line-height: 120px; margin-bottom: 20px;
              img {max-width: 120px;}
    }
  }

  /*** Footer ***/
  footer { padding:30px 0; margin-top: 30px; text-align: center;
    .logo_footer { margin-left:0px; 
      img { width: 130px;}
    }
    .fragen { margin-top: 30px;}
   
  }
  .copyright { text-align:center;margin: 10px 0 30px 0;
              .text-right {text-align:center !important; margin-top: 5px;}
  }

  /*** Grid Layout ***/
  .umb-grid {
            .fotogalerie.grid_modul { margin-left: 0px; margin-right: 0px; margin-top:30px; margin-bottom: 30px;}
  }
  
  /*** Überschriften ***/
  h1 { }
  h2 {}
  h3 {font-size:35px;line-height: 42px;}
  h4 {}
  h5 {}
  
}